<template>

  <v-app>
    <v-main>
    <router-view/>
    </v-main>
  </v-app>

</template>

<script>


export default {
  name: 'App',

  components: {
    
  },
  mounted(){
     if (!localStorage.getItem("bleach-lang")) {
      localStorage.setItem("bleach-lang", "EN");
      //this.selectlan = "EN";
    }
//     window.onload = function() {
// 	if(!window.location.hash) {
// 		window.location = window.location + '#loaded';
// 		window.location.reload();
// 	}
// }
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss" >
  
  .row.no-gutters {
    margin: 0;
}
.row.no-gutters>.col, .row.no-gutters>[class*=col-] {
    padding: 0 ;
}
.vs-notification.vs-notification--color:not(.vs-notification--flat) .vs-notification__close {
    --vs-color: 43,159,173 !important;
}
body{
  font-display: 'Poppins' !important;
}
.v-picker .v-application .cal0 {
    background-color: #ffffff !important;
    border-color: #289BAC !important;
}
  @import './assets/styles/main.scss';
  @import './assets/styles/containers.scss';
.expand-enter-active,
.expand-leave-active {
  transition: height .5s ease-in-out !important;
  overflow: hidden;
}
.v-picker__title{
  border-bottom: 1px solid #289BAC !important;
}
.v-picker__title{
     color: #2B9FAD !important;
}
.v-date-picker-title__year{
  font-weight: 700 !important;
}
.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #2B9FAD !important;
    font-weight: 700 !important;
font-size: 12px !important;
line-height: 16px !important;
}
.v-date-picker-header .v-icon {
  color: #333333 !important;
    font-size:11px !important
}
.theme--light.v-date-picker-table th, .theme--light.v-date-picker-table .v-date-picker-table--date__week {
    color: #2B9FAD !important;
}
.v-picker--full-width{
border: 1px solid #e9ecef !important;
border-radius: 5px !important;
}
.v-picker{
border: 1px solid #e9ecef !important;
border-radius: 5px !important;
}
.v-picker__body {
    padding-top: 10px !important;
    border-top: 1px solid #289bac !important;
}
.v-date-picker-table{
  height: 230px !important;
}

/* width */
::-webkit-scrollbar {
  display: none;
}

/* Track */

</style>
