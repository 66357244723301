import Vue from "vue";
import Vuex from "vuex";
import service from "../services/service"
import utils from "../utils/utils"
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    customer:null,
    cartItems: 0,
    cart_reset_dialog:false,
    cart_id:null,
    userCart:[],
    isCartScheduled:false,
    log_token:localStorage.getItem('bleach-tk'),
    login_dialog:false,
      dialog_box:false,
      download_dialog_box:false,
      cart_dialog:false,
      evaluationStep:1,
      evaluation:{
        booking_date:null,
        booking_time:null,
        evaluation_note:null,
        is_assistance_needed:false,
        address_id:null,
        slot_index:null
      },
      location_types: [
        "Post Construction",
        "Post Renovation",
        "Fully Furnished",
        "Empty Area",
      ],
      cause_of_stain:['INK MARK', 'HARD DUST', 'COFFEE & TEA SPILL', 'OIL','GREASE', 'PAINT', 'URINE', 'MILK SPILL', 'NO STAIN', 'OTHERS'],
      walltypes:["BRICKS","GLASS","CONCRETE","CERAMIC","GYPSUM","FABRIC","RUBBER","STONE","TERRAZO","STAINLESS","VINYL","WOODEN","OTHERS"],
      ceilingtypes:["WOODEN","GLASS","CONCRETE","CERAMIC","GYPSUM","FOAM","PLASTIC","FABRIC","RUBBER","STAINLESS","VENYL","OTHERS"],
      floortypes:["MARBLE","GLASS","STONE","CERAMIC","CONCRETE","BRICKS","WOODEN","TERRAZO","OTHERS"],
      materials:["POLYESTER","NATURAL FIBER","SYNTHETIC","LEATHER","OLEFIN","POLYPROPYLENE","NYLON"],
      colors:["GREEN","SILVER","VIOLET","WHITE","BLACK","BEIGE","BLUE","GREY","RED","CREAM","MULTI","OFF WHITE","MEROON","ORANGE","PINK","GOLD","BROWN","YELLOW","ROYAL BLUE","LILAC","OTHERS"],
  },
  mutations: {
    set_cart(state,data){
        state.dialog_box = data
    },
    set_downloads(state,data){
      state.download_dialog_box = data
  },
    clearEvalution(state){
      state.evaluation = {
        booking_date:null,
        booking_time:null,
        evaluation_note:null,
        is_assistance_needed:false,
        address_id:null
      }
    },
    set_eval_address(state,data){
      state.evaluation.address_id = data
    },
    set_eval_assistn(state){
      state.evaluation.is_assistance_needed = !state.evaluation.is_assistance_needed
    },
    set_eval_date_time(state,data){
      state.evaluation.booking_date = data.d
      state.evaluation.booking_time = data.t
      state.evaluation.slot_index = data.i
    },
    set_evalutionStep(state,data){
      state.evaluationStep = data
    },
    set_eval_note(state,data){
      state.evaluation.evaluation_note = data
    },
    set_cartdialog(state,data){
      state.cart_dialog = data
    },
    set_logindialog(state,data){
      state.login_dialog = data
    },
    set_login_token(state,data){
      state.log_token = data
    },
    set_schedule_flag(state,data){
      state.isCartScheduled = data
    },
    set_user_cart(state,data){
      state.userCart = data
    },
    set_user_cart_id(state,data){
      state.cart_id = data
    },
    set_user(state,data){
      state.customer = data
    },
    changeCart(state, item) {
			state.cartItems = item
		},
  },
  actions: {
    async getUserCart({state,commit}){
      if(state.log_token){
        let result = await service._get(`customer/cart/${state.log_token}/`);
        if (utils.isReqSuccess(result)) {
          commit("set_schedule_flag",result.data.is_scheduled)
          commit("set_user_cart",result.data.data)
          commit("set_user_cart_id",result.data.cart_id)
          commit("set_user",result.data.customer)
          commit("changeCart", result.data.cart_items_count)

        }
        
      }
    }
  },
  modules: {}
});
