<template>
  <div v-cloak style="position: relative">
    <Header />
    <div><br /><br /><br /></div>
    <div class="" style="padding: 17px 0px; background: white" v-if="loginbar">
      <div class="bcontainer">
        <div class="d-flex">
          <div class="full-flex text-right" v-if="log_token">
            <v-menu
              rounded
              offset-y
              :nudge-top="-14"
              :nudge-left="270"
              :nudge-width="275"
              class="elevation-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <svg
                  class="pointer"
                  v-bind="attrs"
                  v-on="on"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.6769 21.9999V19.4153C21.6769 18.0444 21.1323 16.7295 20.1629 15.7601C19.1934 14.7907 17.8786 14.2461 16.5077 14.2461H6.16922C4.79826 14.2461 3.48345 14.7907 2.51403 15.7601C1.54461 16.7295 1 18.0444 1 19.4153V21.9999"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.3384 11.3384C14.1933 11.3384 16.5076 9.02411 16.5076 6.16922C16.5076 3.31434 14.1933 1 11.3384 1C8.48353 1 6.16919 3.31434 6.16919 6.16922C6.16919 9.02411 8.48353 11.3384 11.3384 11.3384Z"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
              <div class="py-7 px-6" style="background-color: #ffffff">
                <div class="p-name mb-7" v-if="$store.state.customer != null">
                  {{ $store.state.customer.name }}
                </div>
                <div
                  class="d-flex mb-3 menu-list-item"
                  @click="gotoMain('my/profile')"
                >
                  <div>
                    <svg
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.6769 21.9999V19.4153C21.6769 18.0444 21.1323 16.7295 20.1629 15.7601C19.1934 14.7907 17.8786 14.2461 16.5077 14.2461H6.16922C4.79826 14.2461 3.48345 14.7907 2.51403 15.7601C1.54461 16.7295 1 18.0444 1 19.4153V21.9999"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.3384 11.3384C14.1933 11.3384 16.5076 9.02411 16.5076 6.16922C16.5076 3.31434 14.1933 1 11.3384 1C8.48353 1 6.16919 3.31434 6.16919 6.16922C6.16919 9.02411 8.48353 11.3384 11.3384 11.3384Z"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="ma ml-4 list-item-text">View Profile</div>
                </div>
                <div
                  class="d-flex mb-3 menu-list-item"
                  @click="gotoMain('my/orders')"
                >
                  <div>
                    <svg
                      width="21"
                      height="23"
                      viewBox="0 0 21 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.15 1L1 5.2V19.9C1 20.457 1.22125 20.9911 1.61508 21.3849C2.0089 21.7787 2.54305 22 3.1 22H17.8C18.357 22 18.8911 21.7787 19.2849 21.3849C19.6787 20.9911 19.9 20.457 19.9 19.9V5.2L16.75 1H4.15Z"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1 5.2002H19.9"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.65 9.3999C14.65 10.5138 14.2075 11.5821 13.4198 12.3698C12.6322 13.1574 11.5639 13.5999 10.45 13.5999C9.33609 13.5999 8.2678 13.1574 7.48015 12.3698C6.6925 11.5821 6.25 10.5138 6.25 9.3999"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="ma ml-4 list-item-text">My Orders</div>
                </div>
                <div
                  class="d-flex mb-3 menu-list-item"
                  @click="gotoMain('my/evaluation')"
                >
                  <div>
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.13 9.94737L16.39 8.55474C16.83 8.06842 17.39 7.80316 18 7.73684V6.63158L12 0H2C0.89 0 0 0.983684 0 2.21053V17.6842C0 18.9 0.89 19.8947 2 19.8947H8V17.8279L8.13 17.6842H2V2.21053H9V9.94737H15.13ZM11 1.65789L16.5 7.73684H11V1.65789ZM16.13 11.97L18.17 14.2247L12.04 21H10V18.7453L16.13 11.97ZM19.85 12.3679L18.87 13.4511L16.83 11.1963L17.81 10.1132C18 9.89211 18.33 9.89211 18.53 10.1132L19.85 11.5721C20.05 11.7932 20.05 12.1579 19.85 12.3679Z"
                        fill="#707070"
                      />
                    </svg>
                  </div>
                  <div class="ma ml-4 list-item-text">My Evaluations</div>
                </div>
                <div
                  style="border-bottom: none"
                  @click="logout()"
                  class="d-flex menu-list-item"
                >
                  <div>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.33333 17H2.77778C2.30628 17 1.8541 16.8127 1.5207 16.4793C1.1873 16.1459 1 15.6937 1 15.2222V2.77778C1 2.30628 1.1873 1.8541 1.5207 1.5207C1.8541 1.1873 2.30628 1 2.77778 1H6.33333"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5557 13.4446L17.0001 9.00011L12.5557 4.55566"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.9999 9H6.33325"
                        stroke="#707070"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="ma ml-4 list-item-text">Log Out</div>
                </div>
              </div>
            </v-menu>
          </div>
          <div v-else class="full-flex text-right">
            <span class="sign-btn pointer" @click="loginWin()"
              >Sign in</span
            >
          </div>
          <div v-if="loginToken" class="ml-4 pointer">
            <a :href="$router.resolve({ path: '/cart' }).href">
              <v-badge
                color="#ffc145"
                :content="appCart ? appCart : '0'"
                right
                small
                offset-y="15px"
              >
                <svg
                  width="24"
                  height="23"
                  viewBox="0 0 24 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 5.66699H20.5883C20.9143 5.667 21.2367 5.73532 21.5347 5.86755C21.8326 5.99977 22.0996 6.19297 22.3183 6.43468C22.5371 6.67639 22.7027 6.96125 22.8046 7.2709C22.9065 7.58055 22.9424 7.90812 22.91 8.23249L22.21 15.2325C22.1524 15.8083 21.883 16.3421 21.454 16.7304C21.0249 17.1186 20.467 17.3336 19.8883 17.3337H8.74667C8.20705 17.3339 7.68404 17.147 7.26669 16.805C6.84933 16.4629 6.56343 15.9868 6.45767 15.4577L4.5 5.66699Z"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.3333 22H19.6667M4.5 5.66667L3.555 1.88317C3.4918 1.63088 3.34611 1.40694 3.14108 1.24693C2.93604 1.08692 2.68342 1.00001 2.42333 1H1L4.5 5.66667ZM8 22H10.3333H8Z"
                    stroke="#707070"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </v-badge>
            </a>
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <Footer />
    <v-dialog persistent v-model="login_dialog" width="500">
      <div class="pa-6" style="background: #fff">
        <div class="d-flex">
          <div style="flex-grow: 1">
            <svg
              width="140"
              height="35"
              viewBox="0 0 140 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z"
                fill="#289BAC"
              />
              <path
                d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z"
                fill="#289BAC"
              />
              <path
                d="M38.8246 33.9972H22.7958V7.04297H28.9956V27.7973H38.8246V33.9972Z"
                fill="#289BAC"
              />
              <path
                d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z"
                fill="#289BAC"
              />
              <path
                d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z"
                fill="#289BAC"
              />
              <path
                d="M76.7514 8.31849L76.3497 7.07568H70.4806L70.0553 8.31849L61.3085 33.9921H67.8722L68.9732 30.7268H77.446L78.5092 33.9921H85.0351L76.7514 8.31849ZM76.5056 22.4004C76.4489 22.6178 76.3686 22.8257 76.2741 23.0195C75.8015 23.9835 74.8895 24.697 73.8168 24.8908C71.6384 25.283 69.734 23.6102 69.734 21.5026C69.734 20.8552 69.923 20.2173 70.2963 19.6077C70.6366 19.0548 71.1517 18.4594 71.6951 17.8262C72.1818 17.2638 72.6922 16.6684 73.1789 16.0069C73.6656 16.6637 74.176 17.2544 74.658 17.8167C75.2156 18.4641 75.7259 19.0595 76.0614 19.603C76.6096 20.4961 76.7608 21.4459 76.5056 22.4004Z"
                fill="#289BAC"
              />
              <g clip-path="url(#clip0_1399_213)">
                <path
                  d="M134.83 3.5601H135.62C135.93 3.5601 136.16 3.4901 136.31 3.3401C136.47 3.1901 136.54 2.9901 136.54 2.7401C136.54 2.4901 136.47 2.2801 136.32 2.1401C136.17 2.0001 135.94 1.9301 135.61 1.9301H134.82V3.5601H134.83ZM136.52 5.5201L135.55 3.8801H134.83V5.5201H134.44V1.6001H135.62C136.06 1.6001 136.39 1.7001 136.62 1.9101C136.85 2.1201 136.96 2.3901 136.96 2.7301C136.96 3.0101 136.88 3.2601 136.72 3.4601C136.56 3.6601 136.32 3.7901 136 3.8501L137.01 5.5201H136.54H136.52Z"
                  fill="#1D1D1B"
                />
                <path
                  d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z"
                  fill="#1D1D1B"
                />
              </g>
              <defs>
                <clipPath id="clip0_1399_213">
                  <rect
                    width="7.12"
                    height="7.12"
                    fill="white"
                    transform="translate(132)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="ma">
            <v-icon
              color="primary"
              @click="
                (loginClose()),
                  (num = true),
                  (reg = false),
                  (log = false)
              "
            >
              mdi-close-circle-outline
            </v-icon>
          </div>
        </div>
        <div class="h-100">
          <div class="py-5 fs-14-en">
            Let’s Quickly Book Your Cleaning Slot with BLEACH
          </div>
          <div>
            <div class="login-text">Login/Register</div>
            <div v-if="num">
              <div class="d-flex mt-10 login-container">
                <div class="service-content pa-2 lg-phone">+965</div>
                <div style="flex-grow: 1">
                  <input
                    type="number"
                    class="w-100 h-100 login-input pl-2"
                    placeholder="Mobile Number"
                    v-model="phone"
                    v-validate="'required|length:8'"
                    name="mphoe"
                    data-vv-as="Mobile Number"
                  />
                </div>
              </div>
              <span class="red-color">{{ errors.first("mphoe") }}</span>
              <v-alert v-if="sms_error" class="mt-5" dense text type="error">
                <span class="red-color"> Failed to sent Otp. Try again </span>
              </v-alert>
              <div class="text-center mt-5">
                <button
                  v-if="!loader"
                  class="btn-otp"
                  :class="phone != '' ? 'active' : ''"
                  @click="otpSubmit()"
                >
                  <span> Get OTP</span>
                </button>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </div>
            <div class="" v-if="log">
              <div class="service-content pt-10 pb-8">
                {{ phone }}
              </div>
              <v-alert v-if="errorFlag" class="mt-5" dense text type="error">
                <span class="red-color"> {{ errortext }}</span>
              </v-alert>
              <!-- <v-otp-input
                length="6"
                class="otp-min-height"
                v-model="logOtp"
                plain
                type="number"
              ></v-otp-input> -->
              <vie-otp-input
                inputClasses="otp-input1"
                :numInputs="6"
                separator
                :is-input-num="true"
                :shouldAutoFocus="true"
                @on-complete="validateOTP"
              />
              <div class="d-flex p-2">
                <div style="flex-grow: 1" class="otp-time">
                  <countdown
                    :key="visible"
                    ref="countdown"
                    :time="2 * 60 * 1000"
                    @end="timeEnd()"
                  >
                    <template slot-scope="props">
                      {{ props.minutes }}:{{ props.seconds }}
                    </template>
                  </countdown>
                </div>
                <div
                  class="otp-time pointer"
                  v-if="resend"
                  @click="resentOtp()"
                >
                  Resend OTP
                </div>
              </div>
              <div class="text-center mt-5">
                <button
                  class="btn-otp active"
                  @click="reqLog()"
                  v-if="logOtp.length == 6"
                >
                  Login
                </button>
                <button class="btn-otp" v-else>Login</button>
              </div>
            </div>
            <div class="" v-if="reg">
              <div class="service-content pt-10 pb-8">
                {{ phone }}
              </div>
              <v-alert v-if="errorFlag" class="mt-5" dense text type="error">
                <span class="red-color"> {{ errortext2 }}</span>
              </v-alert>
              <v-alert v-if="otpnull" class="mt-5" dense text type="error">
                <span class="red-color"> Please complete otp field </span>
              </v-alert>
              <vie-otp-input
                inputClasses="otp-input1"
                :numInputs="6"
                :is-input-num="true"
                separator
                :shouldAutoFocus="true"
                @on-complete="validateOTPSignUp"
              />
              <!-- <v-otp-input
                class="otp-min-height"
                length="6"
                v-model="regCustomer.customer_otp"
                plain
                type="number"
                v-validate="'required'"
                name="otp"
                data-vv-as="otp"
              ></v-otp-input> -->
              <span class="red-color">{{ errors.first("otp") }}</span>

              <div class="d-flex">
                <div style="flex-grow: 1" class="otp-time">
                  <countdown
                    :key="visible"
                    ref="countdown"
                    :time="2 * 60 * 1000"
                    @end="timeEnd()"
                  >
                    <template slot-scope="props">
                      {{ props.minutes }}:{{ props.seconds }}
                    </template>
                  </countdown>
                </div>
                <div
                  class="otp-time pointer"
                  v-if="resend"
                  @click="resentOtp()"
                >
                  Resend OTP
                </div>
              </div>
              <v-text-field
                class="mt-15"
                v-model="regCustomer.name"
                v-validate="'required'"
                name="name"
                data-vv-as="Name"
              >
                <template v-slot:label>
                  <span class="login-label">Name</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("name") }}</span>
              <!-- <v-text-field > 
                 <template  v-slot:label>
                            <span class="login-label">Last Name</span>
                          </template>
              </v-text-field> -->
              <v-text-field
                type="email"
                v-model="regCustomer.email"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
              >
                <template v-slot:label>
                  <span class="login-label">Email</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("email") }}</span>

              <v-select
                v-validate="'required'"
                name="gender"
                data-vv-as="gender"
                v-model="regCustomer.gender"
                :items="genders"
                append-icon="mdi-chevron-down"
              >
                <template v-slot:label>
                  <span class="login-label">Gender</span>
                </template>
              </v-select>
              <span class="red-color">{{ errors.first("gender") }}</span>
              <v-select
                v-validate="'required'"
                name="type"
                data-vv-as="type"
                v-model="regCustomer.customer_type"
                :items="items"
                append-icon="mdi-chevron-down"
              >
                <template v-slot:label>
                  <span class="login-label">Type</span>
                </template>
              </v-select>
              <span class="red-color">{{ errors.first("type") }}</span>
              <v-text-field
                v-if="regCustomer.customer_type == 'CORPORATE'"
                v-model="regCustomer.company"
                v-validate="'required'"
                name="cname"
                data-vv-as="Company name"
              >
                <template v-slot:label>
                  <span class="login-label">Company name</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("cname") }}</span>
              <v-text-field
                v-if="regCustomer.customer_type == 'CORPORATE'"
                v-model="regCustomer.job_title"
                v-validate="'required'"
                name="jobtitile"
                data-vv-as="Job title"
              >
                <template v-slot:label>
                  <span class="login-label">Job title</span>
                </template>
              </v-text-field>
              <span class="red-color">{{ errors.first("jobtitile") }}</span>
              <div class="text-center mt-5">
                <button
                  class="btn-otp"
                  :class="
                    regCustomer.customer_otp != '' &&
                    regCustomer.name != '' &&
                    regCustomer.gender != '' &&
                    regCustomer.email != '' &&
                    regCustomer.customer_type != ''
                      ? 'active'
                      : ''
                  "
                  v-if="!loader"
                  @click="regSubmit"
                >
                  Register
                </button>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
    <div
      style="position: sticky; bottom: 0px; padding-right: 10%"
      class="text-right"
    >
      <svg
        class="pointer"
        width="46"
        height="46"
        viewBox="0 0 76 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        id="myBtn"
        @click="topFunction()"
      >
        <path
          d="M0 76L-2.44784e-06 20C-2.93066e-06 8.95431 8.9543 -3.91405e-07 20 -8.74228e-07L56 -2.44784e-06C67.0457 -2.93066e-06 76 8.9543 76 20L76 76L0 76Z"
          fill="#2B9FAD"
        />
        <path
          d="M26.0001 42.0089C25.9992 41.7528 26.0581 41.4997 26.1724 41.2682C26.2867 41.0368 26.4536 40.8329 26.6608 40.6715L37.374 32.39C37.6935 32.1379 38.0943 32 38.5079 32C38.9214 32 39.3222 32.1379 39.6417 32.39L50.355 40.963C50.7196 41.2541 50.9489 41.6723 50.9924 42.1256C51.036 42.579 50.8902 43.0305 50.5871 43.3806C50.284 43.7307 49.8485 43.9509 49.3764 43.9927C48.9042 44.0345 48.4341 43.8945 48.0695 43.6035L38.4989 35.9393L28.9284 43.3463C28.6663 43.556 28.3472 43.6892 28.0087 43.7301C27.6703 43.771 27.3267 43.718 27.0186 43.5773C26.7106 43.4367 26.4509 43.2142 26.2704 42.9362C26.0899 42.6583 25.9961 42.3365 26.0001 42.0089Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import VieOtpInput from "@bachdgvn/vue-otp-input";
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import service from "../services/service";
import { mapState } from "vuex";
export default {
  components: {
    Header,
    Footer,
    VieOtpInput,
  },
  computed: {
    ...mapState({
      // login_dialog: (state) => state.login_dialog,
      log_token: (state) => state.log_token,
    }),
    appCart() {
      if (this.$store.state.cartItems == undefined) {
        return 0;
      } else {
        return this.$store.state.cartItems;
      }
    },
    login_dialog: {
      get() {
        return this.$store.state.login_dialog;
      },
      set(newName) {
        return newName;
      },
    },
  },
  data() {
    return {
      sms_error: false,
      loginToken: localStorage.getItem("bleach-tk"),
      loginbar: true,
      loged: false,
      step: 1,
      visible: 1,
      resend: false,
      errortext: "Something went wrong",
      errortext2: "Something went wrong",
      errorFlag: false,
      otpnull: false,
      regCustomer: {
        customer_otp: "",
        name: "",
        gender: "",
        email: "",
        customer_type: "",
        sms_preference: "ENGLISH",
        is_sms: true,
        nationality: "KW",

        company: "",
        job_title: "",
        customer_mobile: "",
      },
      genders: ["MALE", "FEMALE", "OTHER"],
      items: ["INDIVIDUAL", "RETAIL", "CORPORATE"],
      loader: false,
      loaded: false,
      available_slots: [],
      regOtp: "",
      phone: "",
      logOtp: "",
      num: true,
      reg: false,
      log: false,
      dialog: false,
      checkFields: false,
    };
  },

  mounted() {
    this.$store.dispatch("getUserCart");
    // this.cartUpdation();
  },
  methods: {
    // async cartUpdation() {
    //   let result = await service._get(
    //     "customer/cart/" + localStorage.getItem("bleach-tk") + "/"
    //   );
    //   if (result.status == 200) {
    //     //console.log("hii")
    //     this.$store.commit("changeCart", result.data.cart_items_count);
    //   }
    // },
    validateOTP(value) {
      this.logOtp = value;
    },
    validateOTPSignUp(value) {
      this.regCustomer.customer_otp = value;
    },
    timeEnd() {
      this.resend = true;
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    gotoMain(loc) {
      window.location = window.location.href.split("/")[0] + "/" + loc;
    },
    logout() {
      localStorage.removeItem("bleach-tk");
      location.reload();
    },
    loginWin() {
      this.$store.commit("set_logindialog", true);
    },
    loginClose() {
      this.$store.commit("set_logindialog", false);
    },
    goto(loc) {
      window.location = loc;
    },
    async resentOtp() {
      if (this.phone != "" && !this.loader) {
        this.resend = false;
        this.loader = true;
        let result = await service._post(
          "api/evaluation-booking-customer-otp-generation/",
          { mobile_number: this.phone }
        );
        this.loader = false;
        if (result.status == 200) {
          this.visible = this.visible + 1;
          if (result.data.sms_status != "false") {
            if (result.data.is_new_customer) {
              this.num = false;
              this.reg = true;
            } else {
              this.num = false;
              this.log = true;
            }
          } else {
            this.sms_error = true;
          }
        } else {
          this.sms_error = true;
        }
      }
    },

    async reqLog() {
      this.loader = true;
      this.errorFlag = false;
      let result = await service._post(
        "api/evaluation-booking-customer-otp-verification/",
        {
          customer_otp: this.logOtp,
          customer_mobile: this.phone,
        }
      );
      this.loader = false;
      if (result.status == 200) {
        if (result.data.otp_verified) {
          localStorage.setItem("bleach-tk", result.data.token);
          this.$store.commit("set_login_token", result.data.token);
          this.$store.dispatch("getUserCart");
          this.loginClose();
          this.$store.commit("changeCart", result.data.cart_items_count);
          // this.login_dialog = false;

          this.loginToken = result.data.token;
        } else {
          this.errortext = result.data.otp_message;
          this.errorFlag = true;
        }
      } else {
        this.errortext = "Something went wrong";

        this.errorFlag = true;
      }
    },
    otpSubmit() {
      this.$validator.validateAll().then((result) => {
        // //console.log(result);
        if (!result) {
          return;
        }
        this.getOtp();
      });
    },
    async regReq() {
      this.loader = true;
      this.errorFlag = false;
      this.regCustomer.customer_mobile = this.phone;
      var fd = new FormData();
      fd.append("customer_otp", this.regCustomer.customer_otp);
      fd.append("customer_mobile", this.regCustomer.customer_mobile);
      fd.append("name", this.regCustomer.name);
      fd.append("gender", this.regCustomer.gender);
      fd.append("customer_type", this.regCustomer.customer_type);
      fd.append("sms_preference", this.regCustomer.sms_preference);
      fd.append("is_sms", this.regCustomer.is_sms);
      fd.append("nationality", this.regCustomer.nationality);
      fd.append("email", this.regCustomer.email);
      fd.append("company", this.regCustomer.company);
      fd.append("job_title", this.regCustomer.job_title);

      let result = await service._post(
        "api/evaluation-booking-customer-otp-verification/",
        fd
      );
      this.loader = false;
      if (result.status == 200) {
        if (result.data.otp_verified) {
          // //console.log("1");
          this.loginClose();
          // this.login_dialog = false;
          this.$store.commit("changeCart", result.data.cart_items_count);

          localStorage.setItem("bleach-tk", result.data.token);
          this.$store.commit("set_login_token", result.data.token);
          this.$store.dispatch("getUserCart");
          this.loginToken = result.data.token;
        } else {
          this.errortext2 = result.data.otp_message;
          this.errorFlag = true;
        }
        // //console.log(result)
        //
      } else {
        this.errortext2 = "Something went wrong";

        this.errorFlag = true;
      }
    },
    async getOtp() {
      if (this.phone != "" && !this.loader) {
        this.sms_error = false;
        this.loader = true;
        let result = await service._post(
          "api/evaluation-booking-customer-otp-generation/",
          { mobile_number: this.phone }
        );
        this.loader = false;
        if (result.status == 200) {
          if (result.data.sms_status != "false") {
            if (result.data.is_new_customer) {
              this.num = false;
              this.reg = true;
            } else {
              this.num = false;
              this.log = true;
            }
          } else {
            this.sms_error = true;
          }
        } else {
          this.sms_error = true;
        }
        // //console.log(result);
      }
    },
    regSubmit() {
      this.$validator.validateAll().then((result) => {
        // //console.log(result);
        if (!result) {
          return;
        }
        this.checkFields = true;
      });
      if (this.regCustomer.customer_otp != "" && this.checkFields == true)
        this.regReq();
      if (this.regCustomer.customer_otp == "") this.otpnull = true;
      setTimeout(() => (this.otpnull = false), 50000);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #2b9fad;
}

.menu-list-item:last-child {
  padding-bottom: 0px;
}
.v-menu__content {
  box-shadow: 0px 4px 4px 6px rgba(0, 0, 0, 0.04) !important;
}
.menu-list-item {
  cursor: pointer;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(218, 218, 218, 0.6);
}
.list-item-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #707070;
}
.p-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #707070;
}
</style>