import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../layouts/index.vue'
import notfound from '../views/404/404.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '*',
    name:'404',
    component:notfound
  },
    {
      
        path: '/',
        name: 'Home',
        component: index,
        redirect: `/home`,
        children: [
            {
                path: 'oldhome',
                component: () => import(/* webpackChunkName: "about" */ '../views/Home/home.vue'),
            },
            {
              path: 'home',
              component: () => import(/* webpackChunkName: "about" */ '../views/Home/newhome.vue'),
          },
            {
              path: 'about-us',
              component: () => import(/* webpackChunkName: "about" */ '../views/About/about.vue'),
            },
            {
              path: 'certifications',
              component: () => import(/* webpackChunkName: "about" */ '../views/About/certifications.vue'),
            },
            {
              path: 'contact-us',
              component: () => import(/* webpackChunkName: "about" */ '../views/About/contact.vue'),
            },
            {
              path: 'terms',
              component: () => import(/* webpackChunkName: "about" */ '../views/About/terms.vue'),
            },
            {
              path: 'privacy',
              component: () => import(/* webpackChunkName: "about" */ '../views/About/privacy.vue'),
            },
            {
              path: 'careers',
              component: () => import(/* webpackChunkName: "about" */ '../views/careers/careers.vue'),
            },
            {
              path: 'teams',
              component: () => import(/* webpackChunkName: "about" */ '../views/careers/teams.vue'),
            },
            {
              path: 'blog',
              component: () => import(/* webpackChunkName: "about" */ '../views/blog/index.vue'),
            },
            {
              path: 'blog-1',
              component: () => import(/* webpackChunkName: "about" */ '../views/blog/blog1.vue'),
            },
             {
              path: 'blog-2',
              component: () => import(/* webpackChunkName: "about" */ '../views/blog/blog2.vue'),
            },
            {
              path: 'blog-3',
              component: () => import(/* webpackChunkName: "about" */ '../views/blog/blog3.vue'),
            },
            {
              path: 'blog-4',
              component: () => import(/* webpackChunkName: "about" */ '../views/blog/blog4.vue'),
            },
            {
              path: 'faq',
              component: () => import(/* webpackChunkName: "about" */ '../views/faq/faq.vue'),
              redirect: `faq/general`,
              children:[
                {
                  path: 'general',
                  component: () => import(/* webpackChunkName: "about" */ '../views/faq/general.vue'),
                },
                {
                  path: 'service',
                  component: () => import(/* webpackChunkName: "about" */ '../views/faq/service.vue'),
                },
                {
                  path: 'technical',
                  component: () => import(/* webpackChunkName: "about" */ '../views/faq/technical.vue'),
                },
              ]
            },
            {
              path: 'residential',
              component: () => import(/* webpackChunkName: "about" */ '../views/residential/index.vue'),
              redirect: `residential/domesticsector`,
              children: [
                {
                  path: 'domesticsector',
                  component: () => import(/* webpackChunkName: "about" */ '../views/residential/DomesticSector.vue'),
                },
                {
                  path: 'events',
                  component: () => import(/* webpackChunkName: "about" */ '../views/residential/Events.vue'),
                },
                {
                  path: 'babycare',
                  component: () => import(/* webpackChunkName: "about" */ '../views/residential/BabyCare.vue'),
                },
                {
                  path: 'moving',
                  component: () => import(/* webpackChunkName: "about" */ '../views/residential/Moving.vue'),
                },
              ]
            },
            {
              path: 'commercial',
              component: () => import(/* webpackChunkName: "about" */ '../views/commercial/index.vue'),
              redirect: `commercial/industrial`,
              children: [
                {
                  path: 'industrial',
                  component: () => import(/* webpackChunkName: "about" */ '../views/commercial/industrial.vue'),
                },
                {
                  path: 'corporate',
                  component: () => import(/* webpackChunkName: "about" */ '../views/commercial/corporate.vue'),
                },
                {
                  path: 'education',
                  component: () => import(/* webpackChunkName: "about" */ '../views/commercial/Education.vue'),
                },
                {
                  path: 'health',
                  component: () => import(/* webpackChunkName: "about" */ '../views/commercial/Health.vue'),
                },
                {
                  path: 'retail',
                  component: () => import(/* webpackChunkName: "about" */ '../views/commercial/Retail.vue'),
                },
                {
                  path: 'sports',
                  component: () => import(/* webpackChunkName: "about" */ '../views/commercial/Sports.vue'),
                },
                {
                  path: 'foods',
                  component: () => import(/* webpackChunkName: "about" */ '../views/commercial/Foods.vue'),
                },
                {
                  path: 'hotels',
                  component: () => import(/* webpackChunkName: "about" */ '../views/commercial/Hotels.vue'),
                },
              ]
            },
            {
              path: 'services',
              component: () => import(/* webpackChunkName: "about" */ '../views/services/index.vue'),
            },
            {
              path: 'downloads',
              component: () => import(/* webpackChunkName: "about" */ '../views/services/downloads.vue'),
            },
            {
              path: 'cart',
              component: () => import(/* webpackChunkName: "about" */ '../views/servicebooking/cart.vue'),
              meta: {
                requiresAuth: true,
              }
            },
            {
              path: 'service',
              component: () => import(/* webpackChunkName: "about" */ '../views/services/layout.vue'),
              redirect: `service/detailedcleaning`,
              children: [
                {
                  path: 'detailedcleaning',
                  component: () => import(/* webpackChunkName: "about" */ '../views/services/detailed/index.vue'),
                  redirect: `detailedcleaning/generalcleaning`,
                  children: [
                    {
                      path: 'generalcleaning',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/detailed/generalcleaning.vue'),
                    },
                    {
                      path: 'deepcleaning',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/detailed/deepcleaning.vue'),
                    },
                    {
                      path: 'storagearea',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/detailed/storagearea.vue'),
                    },
                    {
                      path: 'windowcleaning',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/detailed/windowcleaning.vue'),
                    },
                    {
                      path: 'outdoorcleaning',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/detailed/outdoorcleaning.vue'),
                    },
                    {
                      path: 'carparking',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/detailed/carparking.vue'),
                    },
                    {
                      path: 'facadecleaning',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/detailed/facadecleaning.vue'),
                    },
                  ]
                },
                {
                  path: 'specialcare',
                  component: () => import(/* webpackChunkName: "about" */ '../views/services/specialcare/index.vue'),
                  redirect: `specialcare/mattresscleaning`,
                  children: [
                    {
                      path: 'mattresscleaning',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/specialcare/mattresscleaning.vue'),
                    },
                    {
                      path: 'carpetcleaning',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/specialcare/carpetcleaning.vue'),
                    },
                    {
                      path: 'upholsterycleaning',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/specialcare/upholsterycleaning.vue'),
                    },
                  ]
                },
                {
                  path: 'kitchencleaning',
                  component: () => import(/* webpackChunkName: "about" */ '../views/services/kitchencleaning/index.vue'),
                  redirect: `kitchencleaning/kitchencleaning`,
                  children: [
                    {
                      path: 'kitchencleaning',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/kitchencleaning/kitchencleaning.vue'),
                    },
                    {
                      path: 'kitchenappliances',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/kitchencleaning/kitchenappliances.vue'),
                    },
                  ]
                },
                {
                  path: 'pestcontrol',
                  component: () => import(/* webpackChunkName: "about" */ '../views/services/infectioncontrol/index.vue'),
                  redirect: `pestcontrol/sterilization`,
                  children: [
                    {
                      path: 'sterilization',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/infectioncontrol/sterilization.vue'),
                    },
                    {
                      path: 'pestcontrol',
                      component: () => import(/* webpackChunkName: "about" */ '../views/services/infectioncontrol/pestcontrol.vue'),
                    },
                  ]
                },
              ]
            },
            {
              path: 'evaluation',
              component: () => import(/* webpackChunkName: "about" */ '../views/evalution/index.vue'),
              redirect: `evaluation/timeslots`,
              children: [
                {
                  path: 'timeslots',
                  component: () => import(/* webpackChunkName: "about" */ '../views/evalution/timeslot.vue'),
                },
                {
                  path: 'address',
                  component: () => import(/* webpackChunkName: "about" */ '../views/evalution/address.vue'),
                  meta: {
                    requiresAuth: true,
                  }
                },
                {
                  path: 'instructions',
                  component: () => import(/* webpackChunkName: "about" */ '../views/evalution/instruction.vue'),
                  meta: {
                    requiresAuth: true,
                  }
                },
                {
                  path: 'success',
                  component: () => import(/* webpackChunkName: "about" */ '../views/evalution/success.vue'),
                  meta: {
                    requiresAuth: true,
                  }
                },
              ]
            },
            {
              path: 'my',
              component: () => import(/* webpackChunkName: "about" */ '../views/user/index.vue'),
              meta: {
                requiresAuth: true,
              },
              redirect: `my/orders`,
              children:[
                {
                  name:'My Orders',
                  path: 'orders',
                  component: () => import( '../views/user/orders.vue'),
                },
                {
                  path: 'evaluation',
                  component: () => import(/* webpackChunkName: "about" */ '../views/user/myevaluation.vue'),
                  name:'My evaluation',
                },
                {
                  path: 'address',
                  component: () => import(/* webpackChunkName: "about" */ '../views/user/myaddress.vue'),
                  name:'My Address',
                },
                {
                  path: 'profile',
                  component: () => import(/* webpackChunkName: "about" */ '../views/user/profile.vue'),
                  name:'My Profile',
                },
                {
                  path: 'orderdetails/:id',
                  component: () => import(/* webpackChunkName: "about" */ '../views/user/orderdetails.vue'),
                  meta: {
                    requiresAuth: true,
                  }
                },
              ]
            },
            // {
            //   path: 'myorders',
            //   component: () => import(/* webpackChunkName: "about" */ '../views/user/orders.vue'),
            //   meta: {
            //     requiresAuth: true,
            //   }
            // },
            // {
            //   path: 'myevaluation',
            //   component: () => import(/* webpackChunkName: "about" */ '../views/user/myevaluation.vue'),
            //   meta: {
            //     requiresAuth: true,
            //   }
            // },
            // {
            //   path: 'myaddress',
            //   component: () => import(/* webpackChunkName: "about" */ '../views/user/myaddress.vue'),
            //   meta: {
            //     requiresAuth: true,
            //   }
            // },

        ],
      },
]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('bleach-tk')) {
        next()
        return
      }
      next('/')
    } else {
      next()
    }
  })

  
  
  export default router
  