class Utils {
    isReqSuccess(req){
        if(req.status ==200){
            return req.data.success
        }
        return false
    }
    erroHandler(req){
        if(req.status ==200){
            if(req.data.data != null){
                return req.data.data[0].param+':'+req.data.data[0].msg
            }
            return req.data.msg
        }
        return 'Something went wrong'
    }
}
export default new Utils();