<template>
  <div>
  <div  class="em">
    Thank you for subscribing
  </div>
  </div>
</template>

<script>
//import cartsvgVue from './utils/cartsvg.vue'
export default {
    components:{
       // cartsvgVue
    },
    methods:{
        goto(){
            window.location ='/cart'
        }
    }
}
</script>

<style>
.content-user{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.em{
   color:#2B9FAD;
    font-family: 'Poppins' !important;
font-style: normal !important;
    font-size: .8rem;
    max-width: 200px;
    padding: 0;
    margin: 0
}
</style>