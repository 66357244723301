import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
Vue.config.productionTip = false
import AOS from 'aos'
import 'aos/dist/aos.css'
import TransitionExpand from 'vue-transition-expand'
import 'vue-transition-expand/dist/vue-transition-expand.css'
// import JQuery from 'jquery'
// window.$ = JQuery
import VueParallaxJs from 'vue-parallax-js'
import store from './store'
import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)
import PageLoader from './components/PageLoader'
Vue.component('PageLoader', PageLoader)
Vue.use(VueParallaxJs)
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);
Vue.use(TransitionExpand)
import VueMeta from 'vue-meta'
Vue.use(require('vue-moment'));
import moment from 'moment'
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueCollapse from 'vue2-collapse'
Vue.use(VueCollapse)
Vue.component(VueCountdown.name, VueCountdown);
Vue.prototype.moment = moment
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
})

const options = {
    // You can set your default options here
};
import cartResetDialog from "./components/cartResetDialog"
Vue.component('cart-reset-dialog',cartResetDialog)
import './scss/toatOveride.scss'
Vue.use(Toast, options);
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
new Vue({
  store,
  router,
  vuetify,
  render: h => h(App),
  mounted() {
    AOS.init({
      once: true,
    })
  },
}).$mount('#app')
