<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="500">
      <div class="w-100 bg-white pa-8">
        <div class="text-center sub-heading">Reset ?</div>
        <div class="service-content text-center mt-4">
          Cart is scheduled, you can not add an oder without reseting the already existing schedule of cart item.
          <br><br>
          Do you want to reset schedule ?
        </div>
        <div class="text-center pt-5" v-if="resetloader">
           <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
        </div>
        <div class="row mt-5" v-else>
          <div class="col-md-6">
            <button
              @click="closeDialog"
              type="button"
              class="align-self-end bl-cancel-btn w-100"
              style="margin-top: 20px"
            >
              No
            </button>
          </div>
          <div class="col-md-6">
            <button
              @click="resetSchedule()"
              type="button"
              class="align-self-end bl-primary-btn w-100"
              style="margin-top: 20px"
            >
              YES
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import service from '../services/service';
import utils from '../utils/utils';
export default {
    props:["dialog"],
    data(){
        return{
            resetloader:false
        };
    },
    methods:{
      closeDialog(){
        this.dialog = false, this.$emit("resetcart",false)
      },
        async resetSchedule(){
        this.resetloader =true;
        let result = await service._post('customer/cart/'+localStorage.getItem("bleach-tk")+'/',{
            "action": "reset_schedules"
        })
        if(utils.isReqSuccess(result)){
            this.$store.dispatch('getUserCart')
            this.resetloader = false
            this.dialog = false
            this.$emit("resetcart",true);
        }else{
            this.$emit("resetcart",true);
            this.resetloader = false
            this.dialog = false
            this.$vs.notification({
            duration:'3000',
            position:'top-right',
            text: 'Try again',
            border:"danger",
            title: 'Cart reset failed',
          })
        }
    },
    }
}
</script>

<style>

</style>