<template>
  <div>
    <div v-if="selectlan=='EN'">
    <div class="callus-section">
      <div class="py-7">
        <div class="bcontainer">
          <div class="">
            <div class="row">
              <div  class="col-md-12 call-us-text text-center top-center">
               Have any more queries ? <span style="font-weight: 600;">Call us</span> now at <span style="font-weight: 700;">1882707</span>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="show-callus" style="background: #EDF9FA">
      <div class="py-3">
        <div class="container">
          <div class="px-15">
            <div class="row">
              <div class="col-md-6 gray-text callus text-center-res">
                Call Us <span class="w700">1882707</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-section" >
      <div class="bcontainer">
        <div class="py-2 row mb-1">
          <div class="col-md-6 mt-3">
            <ul class="footer-links-ul">
              <li> <a :href="$router.resolve({path: '/about-us'}).href" > About Us </a> </li>
              <li class="show-callus">|</li>
              <li  ><a :href="$router.resolve({path: '/service/detailedcleaning'}).href"> Services</a></li>
              <li class="show-callus">|</li>

              <li> <a  :href="$router.resolve({path: '/contact-us'}).href" > Contact Us </a></li>
              <li class="show-callus">|</li>

              <li> <a  :href="$router.resolve({path: '/blog'}).href" > Blog</a></li>
            </ul>
          </div>
          <div class="col-md-6 mt-3">
            <ul class="footer-links-ul footer-links-right ">
              <li><a  :href="$router.resolve({path: '/terms'}).href" > T&C</a></li>
              <li class="show-callus">|</li>
              <li><a   :href="$router.resolve({path: '/privacy'}).href" > Privacy Policy</a></li>
              <li class="show-callus">|</li>
              <li><a  :href="$router.resolve({path: '/careers'}).href"> Career</a></li>
              <li class="show-callus" >|</li>
              <li style="margin-right:0px"> <a :href="$router.resolve({path: '/faq'}).href" > FAQs </a> </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6  text-center-res">
            <a href="mailto:customercare@bleach-kw.com"  class="mail-link footer-link">
             
              <v-icon color="white" class="mr-3">
                mdi-email
              </v-icon>
              customercare@bleach-kw.com
            </a>
          </div>
          <div class="col-md-6  mmt-10 text-right-res ">
            <a  class="mail-link footer-link" onclick="window.open('https://www.facebook.com/BleachKw/', '_blank')">
              <v-icon color="white" class="mr-5">
                mdi-facebook
              </v-icon>
              
            </a>
            <a onclick="window.open('https://twitter.com/bleachkw?lang=en', '_blank')" class="mail-link footer-link"> 
              <v-icon color="white" class="mr-5">
                mdi-twitter
              </v-icon>
            </a>
            <a onclick="window.open('https://www.instagram.com/bleachkw/', '_blank')" class="mail-link footer-link">
            <svg width="21" class="mr-5" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.1726 6.78199C8.30421 6.78199 6.77945 8.30676 6.77945 10.1752C6.77945 12.0436 8.30421 13.5683 10.1726 13.5683C12.041 13.5683 13.5658 12.0436 13.5658 10.1752C13.5658 8.30676 12.041 6.78199 10.1726 6.78199ZM20.3496 10.1752C20.3496 8.77004 20.3623 7.37764 20.2834 5.97507C20.2045 4.34594 19.8328 2.90009 18.6415 1.70879C17.4477 0.51495 16.0044 0.145851 14.3753 0.0669404C12.9701 -0.0119704 11.5777 0.000757147 10.1752 0.000757147C8.77004 0.000757147 7.37765 -0.0119704 5.97507 0.0669404C4.34594 0.145851 2.90009 0.517495 1.70879 1.70879C0.51495 2.90264 0.145851 4.34594 0.0669404 5.97507C-0.0119704 7.38019 0.000757147 8.77258 0.000757147 10.1752C0.000757147 11.5777 -0.0119704 12.9727 0.0669404 14.3752C0.145851 16.0044 0.517496 17.4502 1.70879 18.6415C2.90264 19.8354 4.34594 20.2045 5.97507 20.2834C7.38019 20.3623 8.77258 20.3496 10.1752 20.3496C11.5803 20.3496 12.9727 20.3623 14.3753 20.2834C16.0044 20.2045 17.4502 19.8328 18.6415 18.6415C19.8354 17.4477 20.2045 16.0044 20.2834 14.3752C20.3648 12.9727 20.3496 11.5803 20.3496 10.1752ZM10.1726 15.396C7.28346 15.396 4.95177 13.0643 4.95177 10.1752C4.95177 7.28601 7.28346 4.95432 10.1726 4.95432C13.0618 4.95432 15.3935 7.28601 15.3935 10.1752C15.3935 13.0643 13.0618 15.396 10.1726 15.396ZM15.6073 5.95979C14.9327 5.95979 14.388 5.41506 14.388 4.7405C14.388 4.06594 14.9327 3.5212 15.6073 3.5212C16.2818 3.5212 16.8266 4.06594 16.8266 4.7405C16.8268 4.90067 16.7954 5.05932 16.7342 5.20734C16.673 5.35536 16.5832 5.48986 16.4699 5.60312C16.3566 5.71638 16.2221 5.80619 16.0741 5.86739C15.9261 5.9286 15.7675 5.96 15.6073 5.95979Z" fill="white"/>
</svg>

            </a>
            <a onclick="window.open('https://www.linkedin.com/company/bleachkw', '_blank')"  class="mail-link footer-link">
              <v-icon color="white" class="mr-5">
                mdi-linkedin
              </v-icon>
            </a>
            <a onclick="window.open('https://www.youtube.com/channel/UC_XhgxfirQrft_kAFRZPAOw', '_blank')" class="mail-link footer-link ">
               <v-icon color="white" class="">
                mdi-youtube
              </v-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="top-news bcontainer">
      <div class="subcribe-section" style="">
        <div class="row">
          <div class="col-md-3 mt-1 text-center-res">
        <a href="/">
        <svg width="140" height="35" viewBox="0 0 140 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z" fill="#289BAC"/>
<path d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z" fill="#289BAC"/>
<path d="M38.8246 33.9972H22.7958V7.04297H28.9956V27.7973H38.8246V33.9972Z" fill="#289BAC"/>
<path d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z" fill="#289BAC"/>
<path d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z" fill="#289BAC"/>
<path d="M76.7514 8.31849L76.3497 7.07568H70.4806L70.0553 8.31849L61.3085 33.9921H67.8722L68.9732 30.7268H77.446L78.5092 33.9921H85.0351L76.7514 8.31849ZM76.5056 22.4004C76.4489 22.6178 76.3686 22.8257 76.2741 23.0195C75.8015 23.9835 74.8895 24.697 73.8168 24.8908C71.6384 25.283 69.734 23.6102 69.734 21.5026C69.734 20.8552 69.923 20.2173 70.2963 19.6077C70.6366 19.0548 71.1517 18.4594 71.6951 17.8262C72.1818 17.2638 72.6922 16.6684 73.1789 16.0069C73.6656 16.6637 74.176 17.2544 74.658 17.8167C75.2156 18.4641 75.7259 19.0595 76.0614 19.603C76.6096 20.4961 76.7608 21.4459 76.5056 22.4004Z" fill="#289BAC"/>
<g clip-path="url(#clip0_1399_213)">
<path d="M134.83 3.5601H135.62C135.93 3.5601 136.16 3.4901 136.31 3.3401C136.47 3.1901 136.54 2.9901 136.54 2.7401C136.54 2.4901 136.47 2.2801 136.32 2.1401C136.17 2.0001 135.94 1.9301 135.61 1.9301H134.82V3.5601H134.83ZM136.52 5.5201L135.55 3.8801H134.83V5.5201H134.44V1.6001H135.62C136.06 1.6001 136.39 1.7001 136.62 1.9101C136.85 2.1201 136.96 2.3901 136.96 2.7301C136.96 3.0101 136.88 3.2601 136.72 3.4601C136.56 3.6601 136.32 3.7901 136 3.8501L137.01 5.5201H136.54H136.52Z" fill="#1D1D1B"/>
<path d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z" fill="#1D1D1B"/>
</g>
<defs>
<clipPath id="clip0_1399_213">
<rect width="7.12" height="7.12" fill="white" transform="translate(132)"/>
</clipPath>
</defs>
</svg>

        </a>
          </div>
          <div class="col-md-9 mt-1 subscribeform-align-right">
            <div>
              <div class="f-form">
                <div class="newsletter-text f-form__label">Newsletters</div>
                <div class="d-flex btnipt">
                  <input type="text" placeholder="Your Email Address" class="newsletter-input"   v-validate="'required|email'" name="email" data-vv-as="email" v-model="mail">  <button class="footer-btn" @click="validate">
                    Submit
                  </button>
                </div>
               
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="bottom-news">
       <div class="bcontainer">
         <div class="subcribe-section" style="padding: 20px 0px 27px 0px">
        <div class="row">
          <div class="col-md-3 mt-5 text-center-res">
        <a href="/">    
        <svg width="140" height="35" viewBox="0 0 140 35" class="mt-2" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z" fill="#289BAC"/>
<path d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z" fill="#289BAC"/>
<path d="M38.8246 33.9972H22.7958V7.04297H28.9956V27.7973H38.8246V33.9972Z" fill="#289BAC"/>
<path d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z" fill="#289BAC"/>
<path d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z" fill="#289BAC"/>
<path d="M76.7514 8.31849L76.3497 7.07568H70.4806L70.0553 8.31849L61.3085 33.9921H67.8722L68.9732 30.7268H77.446L78.5092 33.9921H85.0351L76.7514 8.31849ZM76.5056 22.4004C76.4489 22.6178 76.3686 22.8257 76.2741 23.0195C75.8015 23.9835 74.8895 24.697 73.8168 24.8908C71.6384 25.283 69.734 23.6102 69.734 21.5026C69.734 20.8552 69.923 20.2173 70.2963 19.6077C70.6366 19.0548 71.1517 18.4594 71.6951 17.8262C72.1818 17.2638 72.6922 16.6684 73.1789 16.0069C73.6656 16.6637 74.176 17.2544 74.658 17.8167C75.2156 18.4641 75.7259 19.0595 76.0614 19.603C76.6096 20.4961 76.7608 21.4459 76.5056 22.4004Z" fill="#289BAC"/>
<g clip-path="url(#clip0_1399_213)">
<path d="M134.83 3.5601H135.62C135.93 3.5601 136.16 3.4901 136.31 3.3401C136.47 3.1901 136.54 2.9901 136.54 2.7401C136.54 2.4901 136.47 2.2801 136.32 2.1401C136.17 2.0001 135.94 1.9301 135.61 1.9301H134.82V3.5601H134.83ZM136.52 5.5201L135.55 3.8801H134.83V5.5201H134.44V1.6001H135.62C136.06 1.6001 136.39 1.7001 136.62 1.9101C136.85 2.1201 136.96 2.3901 136.96 2.7301C136.96 3.0101 136.88 3.2601 136.72 3.4601C136.56 3.6601 136.32 3.7901 136 3.8501L137.01 5.5201H136.54H136.52Z" fill="#1D1D1B"/>
<path d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z" fill="#1D1D1B"/>
</g>
<defs>
<clipPath id="clip0_1399_213">
<rect width="7.12" height="7.12" fill="white" transform="translate(132)"/>
</clipPath>
</defs>
</svg>

        </a>
          </div>
          <div class="col-md-9 mt-5 subscribeform-align-right">
            <div>
              <div class="f-form">
                <div class="newsletter-text f-form__label">Newsletters</div>
                <div class="d-flex btnipt">
                  <input type="text" placeholder="Your Email Address" class="newsletter-input" v-validate="'required|email'" name="email" data-vv-as="email" v-model="mail">  <button class="footer-btn" @click="validate" >
                    Submit
                  </button>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
       </div>
      
    </div>
    <div class="bcontainer mmt-10 copy-footer">
      
      <div class="container">
        <div class="row">
          <div class="col-md-6 ma h-100 text-center-res ">
            
            <div class="copyright ">
            Bleach © 2022 All Rights Reserved | Powered by <a  style="text-decoration: none">SLOTS</a>  
            </div>
          </div>
          <div class="col-md-6 mmt-10  text-right-res">
           <img src="../assets/icons/visa.png" alt="">
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
   <div v-if="selectlan=='ARABIC'">
    <div class="callus-section" dir="rtl">
      <div class="py-7">
        <div class="bcontainer">
          <div class="px-15">
            <div class="row">
             <div  class="col-md-12 call-us-text text-center top-center arabic-font" dir="rtl">
               عندك استفسارات ثانية؟ تواصل ويانا على 1882707
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="callus-section show-callus">
      <div class="py-3">
        <div class="container">
          <div class="px-15">
            <div class="row">
              <div class="col-md-6 gray-text callus text-center-res arabic-font" dir="rtl">
                اتصل بنا <span class="w700">1882707</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-section"  dir="rtl" >
      <div class="bcontainer">
        <div class="py-2 row">
          <div class="col-md-6 mt-3">
            <ul class="footer-links-ul ">
              <li style="margin-right: 0px;"> <a :href="$router.resolve({path: '/about-us'}).href" class="arabic-font" > نبذة عنا </a> </li>
              <li class="show-callus">|</li>
              <li  ><a :href="$router.resolve({path: '/service/detailedcleaning'}).href" class="arabic-font">خدمات</a></li>
              <li class="show-callus">|</li>

              <li><a  :href="$router.resolve({path: '/contact-us'}).href" class="arabic-font"> اتصل بنا </a></li>
              <li class="show-callus">|</li>

              <li><a  :href="$router.resolve({path: '/blog'}).href" class="arabic-font">المدونة </a></li>
            </ul>
          </div>
          <div class="col-md-6 mt-3">
            <ul class="footer-links-ul footer-links-left mr-2">
              <li><a  :href="$router.resolve({path: '/terms'}).href" class="arabic-font" > الشروط و الأحكام</a></li>
              <li class="show-callus">|</li>
              <li><a   :href="$router.resolve({path: '/privacy'}).href" class="arabic-font" > سياسة الخصوصية</a></li>
              <li class="show-callus">|</li>
              <li><a  :href="$router.resolve({path: '/careers'}).href" class="arabic-font"> وظائف</a></li>
              <li class="show-callus">|</li>
              <li > <a :href="$router.resolve({path: '/faq'}).href" class="arabic-font" > الأسئلة الشائعة </a> </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 mt-1 text-center-res">
            <a href="mailto:customercare@bleach-kw.com"  class="mail-link footer-link  arabic-font">
             <v-icon color="white" class="ml-3">
                mdi-email
              </v-icon>
              customercare@bleach-kw.com
            </a>
          </div>
          <div class="col-md-6 mt-1 text-left-res ">
             <a onclick="window.open('https://www.facebook.com/BleachKw/', '_blank')" class="mail-link footer-link">
              <v-icon color="white" class="mr-5">
                mdi-facebook
              </v-icon>
              
            </a>
            <a onclick="window.open('https://twitter.com/bleachkw?lang=en', '_blank')" class="mail-link footer-link"> 
              <v-icon color="white" class="mr-5">
                mdi-twitter
              </v-icon>
            </a>
            <a onclick="window.open('https://www.instagram.com/bleachkw/', '_blank')" class="mail-link footer-link">
            <svg width="21" class="mr-5" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.1726 6.78199C8.30421 6.78199 6.77945 8.30676 6.77945 10.1752C6.77945 12.0436 8.30421 13.5683 10.1726 13.5683C12.041 13.5683 13.5658 12.0436 13.5658 10.1752C13.5658 8.30676 12.041 6.78199 10.1726 6.78199ZM20.3496 10.1752C20.3496 8.77004 20.3623 7.37764 20.2834 5.97507C20.2045 4.34594 19.8328 2.90009 18.6415 1.70879C17.4477 0.51495 16.0044 0.145851 14.3753 0.0669404C12.9701 -0.0119704 11.5777 0.000757147 10.1752 0.000757147C8.77004 0.000757147 7.37765 -0.0119704 5.97507 0.0669404C4.34594 0.145851 2.90009 0.517495 1.70879 1.70879C0.51495 2.90264 0.145851 4.34594 0.0669404 5.97507C-0.0119704 7.38019 0.000757147 8.77258 0.000757147 10.1752C0.000757147 11.5777 -0.0119704 12.9727 0.0669404 14.3752C0.145851 16.0044 0.517496 17.4502 1.70879 18.6415C2.90264 19.8354 4.34594 20.2045 5.97507 20.2834C7.38019 20.3623 8.77258 20.3496 10.1752 20.3496C11.5803 20.3496 12.9727 20.3623 14.3753 20.2834C16.0044 20.2045 17.4502 19.8328 18.6415 18.6415C19.8354 17.4477 20.2045 16.0044 20.2834 14.3752C20.3648 12.9727 20.3496 11.5803 20.3496 10.1752ZM10.1726 15.396C7.28346 15.396 4.95177 13.0643 4.95177 10.1752C4.95177 7.28601 7.28346 4.95432 10.1726 4.95432C13.0618 4.95432 15.3935 7.28601 15.3935 10.1752C15.3935 13.0643 13.0618 15.396 10.1726 15.396ZM15.6073 5.95979C14.9327 5.95979 14.388 5.41506 14.388 4.7405C14.388 4.06594 14.9327 3.5212 15.6073 3.5212C16.2818 3.5212 16.8266 4.06594 16.8266 4.7405C16.8268 4.90067 16.7954 5.05932 16.7342 5.20734C16.673 5.35536 16.5832 5.48986 16.4699 5.60312C16.3566 5.71638 16.2221 5.80619 16.0741 5.86739C15.9261 5.9286 15.7675 5.96 15.6073 5.95979Z" fill="white"/>
</svg>

            </a>
            <a onclick="window.open('https://www.linkedin.com/company/bleachkw', '_blank')" class="mail-link footer-link">
              <v-icon color="white" class="mr-5">
                mdi-linkedin
              </v-icon>
            </a>
            <a onclick="window.open('https://www.youtube.com/channel/UC_XhgxfirQrft_kAFRZPAOw', '_blank')" class="mail-link footer-link">
               <v-icon color="white" class="mr-5">
                mdi-youtube
              </v-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="top-news bcontainer">
      <div class="subcribe-section" style="">
        <div class="row">
          <div class="col-md-3 mt-1 text-center-res">
        <a href="/">   
        <svg width="140" height="35" class="mt-2" viewBox="0 0 140 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z" fill="#289BAC"/>
<path d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z" fill="#289BAC"/>
<path d="M38.8248 33.9972H22.7959V7.04297H28.9957V27.7973H38.8248V33.9972Z" fill="#289BAC"/>
<path d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z" fill="#289BAC"/>
<path d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z" fill="#289BAC"/>
<path d="M76.7515 8.31849L76.3498 7.07568H70.4808L70.0555 8.31849L61.3086 33.9921H67.8723L68.9733 30.7268H77.4461L78.5094 33.9921H85.0353L76.7515 8.31849ZM76.5058 22.4004C76.4491 22.6178 76.3687 22.8257 76.2742 23.0195C75.8017 23.9835 74.8896 24.697 73.817 24.8908C71.6385 25.283 69.7341 23.6102 69.7341 21.5026C69.7341 20.8552 69.9232 20.2173 70.2965 19.6077C70.6367 19.0548 71.1518 18.4594 71.6952 17.8262C72.1819 17.2638 72.6923 16.6684 73.179 16.0069C73.6657 16.6637 74.1761 17.2544 74.6581 17.8167C75.2157 18.4641 75.7261 19.0595 76.0616 19.603C76.6097 20.4961 76.7609 21.4459 76.5058 22.4004Z" fill="#289BAC"/>
<g clip-path="url(#clip0_1435_865)">
<path d="M134.83 3.55998H135.62C135.93 3.55998 136.16 3.48998 136.31 3.33998C136.47 3.18998 136.54 2.98998 136.54 2.73998C136.54 2.48998 136.47 2.27998 136.32 2.13998C136.17 1.99998 135.94 1.92998 135.61 1.92998H134.82V3.55998H134.83ZM136.52 5.51998L135.55 3.87998H134.83V5.51998H134.44V1.59998H135.62C136.06 1.59998 136.39 1.69998 136.62 1.90998C136.85 2.11998 136.96 2.38998 136.96 2.72998C136.96 3.00998 136.88 3.25998 136.72 3.45998C136.56 3.65998 136.32 3.78998 136 3.84998L137.01 5.51998H136.54H136.52Z" fill="#1D1D1B"/>
<path d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z" fill="#1D1D1B"/>
</g>
<defs>
<clipPath id="clip0_1435_865">
<rect width="7.12" height="7.12" fill="white" transform="translate(132)"/>
</clipPath>
</defs>
</svg>

        </a>
          </div>
          <div class="col-md-9 mt-1 subscribeform-align-right">
            <div>
              <div class="f-form" dir="">
               
                <div class="d-flex btnipt">
                   <button class="footer-btn arabic-font" @click="validate">
                    قدم
                  </button>
                  <input type="text" style="text-align:right;padding-right: 20px;" placeholder="بريدك الإلكتروني" v-validate="'required|email'" name="email" data-vv-as="email" v-model="mail" class="newsletter-input arabic-font"> 
                </div>
                 <div class="newsletter-text f-form__label arabic-font">رسائل أخبارنا</div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="bottom-news">
       <div class="container">
         <div class="subcribe-section" style="padding: 20px 10px 27px 10px;">
        <div class="row">
          <div class="col-md-3 mt-5 text-center-res">
        <a href="/">    
        <svg width="140" height="35" viewBox="0 0 140 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M124.8 7.08008H131V33.9965H124.8V23.5815H117.027V33.9965H110.827V7.08008H117.027V17.3816H124.8V7.08008Z" fill="#289BAC"/>
        <path d="M20.0361 26.1145C20.0361 30.8448 17.1535 34.181 10.5709 34.181H0V7.04248H10.1787C15.5516 7.04248 18.4341 10.029 18.4341 14.5229C18.4341 16.0729 17.8671 18.3222 16.1234 19.7162C19.0012 20.959 20.0361 23.6337 20.0361 26.1145ZM6.79525 13.6393V17.3629H10.2118C11.7428 17.3629 12.4517 16.5502 12.4517 15.5011C12.4517 14.452 11.7381 13.6393 10.2118 13.6393H6.79525ZM13.9118 25.8783C13.9118 24.5977 12.9526 23.5911 11.2089 23.5911H6.79525V28.1654H11.2089C12.9526 28.1654 13.9118 27.1164 13.9118 25.8783Z" fill="#289BAC"/>
        <path d="M38.8248 33.9972H22.7959V7.04297H28.9957V27.7973H38.8248V33.9972Z" fill="#289BAC"/>
        <path d="M58.5866 13.2752H47.5148V17.3438H56.4979V23.5437H47.5148V27.7966H58.5866V33.9965H41.3149V7.11316L58.5866 7.08008V13.2752Z" fill="#289BAC"/>
        <path d="M98.4933 33.9589C96.7591 33.9589 95.0626 33.6376 93.4134 32.9855C91.7642 32.3381 90.2757 31.3552 88.9573 30.032C87.6862 28.7372 86.7127 27.2771 86.0417 25.6515C85.3707 24.0259 85.0352 22.3106 85.0352 20.5007C85.0352 18.6672 85.3707 16.9377 86.0417 15.3121C86.7127 13.6866 87.6862 12.2406 88.9573 10.9647C90.2757 9.64627 91.769 8.65392 93.4324 7.99235C95.0957 7.33078 96.7874 7 98.5122 7C100.237 7 101.924 7.33078 103.573 7.99235C105.222 8.65392 106.706 9.64155 108.029 10.9647L103.663 15.3263C102.954 14.6175 102.146 14.0882 101.244 13.7338C100.341 13.3794 99.4243 13.1998 98.4933 13.1998C97.5624 13.1998 96.6551 13.3794 95.762 13.7338C94.8689 14.0882 94.0703 14.6175 93.3615 15.3263C92.6763 16.0351 92.1517 16.829 91.7832 17.708C91.4146 18.5869 91.235 19.5178 91.235 20.496C91.235 21.4742 91.4193 22.3956 91.7832 23.2651C92.1517 24.1346 92.6763 24.9238 93.3615 25.6326C94.0703 26.3414 94.8689 26.8754 95.762 27.2298C96.6551 27.5842 97.5671 27.7591 98.4933 27.7591C99.4195 27.7591 100.341 27.5842 101.244 27.2298C102.146 26.8754 102.954 26.3461 103.663 25.6326L108.029 30.032C106.711 31.3504 105.232 32.3381 103.592 32.9855C101.952 33.6328 100.256 33.9589 98.4933 33.9589Z" fill="#289BAC"/>
        <path d="M76.7515 8.31849L76.3498 7.07568H70.4808L70.0555 8.31849L61.3086 33.9921H67.8723L68.9733 30.7268H77.4461L78.5094 33.9921H85.0353L76.7515 8.31849ZM76.5058 22.4004C76.4491 22.6178 76.3687 22.8257 76.2742 23.0195C75.8017 23.9835 74.8896 24.697 73.817 24.8908C71.6385 25.283 69.7341 23.6102 69.7341 21.5026C69.7341 20.8552 69.9232 20.2173 70.2965 19.6077C70.6367 19.0548 71.1518 18.4594 71.6952 17.8262C72.1819 17.2638 72.6923 16.6684 73.179 16.0069C73.6657 16.6637 74.1761 17.2544 74.6581 17.8167C75.2157 18.4641 75.7261 19.0595 76.0616 19.603C76.6097 20.4961 76.7609 21.4459 76.5058 22.4004Z" fill="#289BAC"/>
        <g clip-path="url(#clip0_1435_865)">
        <path d="M134.83 3.55998H135.62C135.93 3.55998 136.16 3.48998 136.31 3.33998C136.47 3.18998 136.54 2.98998 136.54 2.73998C136.54 2.48998 136.47 2.27998 136.32 2.13998C136.17 1.99998 135.94 1.92998 135.61 1.92998H134.82V3.55998H134.83ZM136.52 5.51998L135.55 3.87998H134.83V5.51998H134.44V1.59998H135.62C136.06 1.59998 136.39 1.69998 136.62 1.90998C136.85 2.11998 136.96 2.38998 136.96 2.72998C136.96 3.00998 136.88 3.25998 136.72 3.45998C136.56 3.65998 136.32 3.78998 136 3.84998L137.01 5.51998H136.54H136.52Z" fill="#1D1D1B"/>
        <path d="M135.56 7.12C133.6 7.12 132 5.52 132 3.56C132 1.6 133.6 0 135.56 0C137.52 0 139.12 1.6 139.12 3.56C139.12 5.52 137.52 7.12 135.56 7.12ZM135.56 0.26C133.74 0.26 132.26 1.74 132.26 3.56C132.26 5.38 133.74 6.86 135.55 6.86C137.36 6.86 138.85 5.38 138.85 3.56C138.85 1.74 137.38 0.26 135.56 0.26Z" fill="#1D1D1B"/>
        </g>
        <defs>
        <clipPath id="clip0_1435_865">
        <rect width="7.12" height="7.12" fill="white" transform="translate(132)"/>
        </clipPath>
        </defs>
        </svg>

         </a>
          </div>
          <div class="col-md-9 mt-5 subscribeform-align-right">
            <div>
              <div class="f-form">
               
                <div class="d-flex btnipt">
                  <button class="footer-btn arabic-font" @click="validate">
                    قدم
                  </button>
                  <input type="text" style="text-align:right;padding-right: 20px;"  placeholder="بريدك الإلكتروني" class="newsletter-input arabic-font" v-validate="'required|email'" name="email" data-vv-as="email" v-model="mail">  
                </div>
                 <div class="newsletter-text f-form__label arabic-font">رسائل أخبارنا</div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
       </div>
      
    </div>
    <div class="bcontainer mmt-10 copy-footer" dir="rtl">
      
      <div class="container">
        <div class="row">
          <div class="col-md-6 ma h-100 text-center-res ">
            
            <div class="copyright mr-3 arabic-font">
            بليتش © 2022  كل الحقوق محفوظة | التشغيل من SLOTS

            </div>
          </div>
          <div class="col-md-6 mmt-10  text-left-res">
           <img src="../assets/icons/visa.png" alt="">
          </div>
        </div>
        <br>
      </div>
    </div>
  </div>
  
  </div>
</template>

<script>

import email from './email.vue' 
import email2 from './email2.vue' 
import email3 from './email3.vue' 

import service from '../services/service';
export default {
       data(){
        return{
          loading:false,
          mail:'',
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
       validate() {
        this.$validator.validateAll().then((result) => {
          if (!result) {
            this.$vs.notification({
            duration:'5000',
            color:'#fff',
            position:'top-right',
            content:email,
             border:"danger",
          })
            return;
          }
          this.subscribe()
        });
      },
      validateState(ref) {
        if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
        ) {
          return !this.veeErrors.has(ref);
        }
        return null;
      },
      async subscribe(){
        this.loading= true
        let result = await service._post('api/mail-subscription/',{
          customer_email:this.mail
        })
        this.loading= false

        if(result.status==200){
         this.mail = ''
         if(result.data.message =='Email already subscribed !'){
          this.$vs.notification({
            duration:'5000',
            color:'#EDF9FA',
            position:'top-right',
            content:email3,
             border:"warn",
          })
         }else{
            this.$vs.notification({
            duration:'5000',
            color:'#EDF9FA',
            position:'top-right',
            content:email2,
             border:"success",
          })
         }  
        }
        
      },
    }
};
</script>

<style></style>
