import axios from "axios";
class Service {
    constructor() {
      this.token = localStorage.getItem("bleach-tk");
    //  this.api = 'http://mytest.bleach-kw.com/';
    //  this.api = 'https://mytest.bleach-kw.com/';
    this.api = 'https://my.bleachkw.com/'
    //this.api='http://127.0.0.1:8000/'

     
    }  
    addHeaders() {
      if (localStorage.getItem("bleach-tk")) {
        let config = {
          headers: {
            "Authorization": 'Token '+localStorage.getItem("bleach-tk")
          }
        };
        return config;
      } else {
        return;
      }
    }
  
    async _get(url) {
      let result;   await axios
        .get(this.api + url, this.addHeaders())
        .then(response => {
          result = response;
        })
        .catch(({ response }) => {
          result = response;
        });
      return result;
    }
    async _get2(url) {
      let result;
      await axios
        .get(this.api + url, this.addHeaders())
        .then(response => {
          result = response;
        })
        .catch(({ response }) => {
          result = response;
        });
      return result;
    }
    async _getCommon(url) {
      let result;
      await axios
        .get(url)
        .then(response => {
          result = response;
        })
        .catch(({ response }) => {
          result = response;
        });
      return result;
    }
  
    async _post(url, data) {
      let result;
      await axios
        .post(this.api + url, data, this.addHeaders())
        .then(response => {
          result = response;
        })
        .catch(({ response }) => {
          result = response;
        });
      return result;
    }
    async _delete(url) {
      let result;
      await axios
        .delete(this.api + url, this.addHeaders())
        .then(response => {
          result = response;
        })
        .catch(({ response }) => {
          result = response;
        });
      return result;
    }
    async _delete2(url,data) {
      let result;
      await axios
        .delete(this.api + url,data, this.addHeaders())
        .then(response => {
          result = response;
        })
        .catch(({ response }) => {
          result = response;
        });
      return result;
    }
    async _patch(url, data) {
      let result;
      await axios
        .patch(this.api + url, data, this.addHeaders())
        .then(response => {
          result = response;
        })
        .catch(({ response }) => {
          result = response;
        });
      return result;
    }
    async _put(url, data) {
      let result;
      await axios
        .put(this.api + url, data, this.addHeaders())
        .then(response => {
          result = response;
        })
        .catch(({ response }) => {
          result = response;
        });
      return result;
    }
  
    setErrorHandler(handler) {
      this.errorHandler = handler;
    }
  
    onError(url, res) {
      if (this.errorHandler) {
        this.errorHandler(url, res);
      }
    }
  }
  export default new Service();
  