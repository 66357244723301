import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@fortawesome/fontawesome-free/css/all.css'
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
    theme: {
        themes: {
          light: {
            
            primary: '#2B9FAD', 
            cal0:'#fff'

  
            
          },
        },
      },
});
